<template>
  <div class="feature-films">
    <FilmThumbnail :films="reversedFilms" :routeName="$route.name" :componentName="'ShortFilm'" />

  </div>
</template>

<script>
import FilmThumbnail from './FilmThumbnail.vue';

export default {
  name: 'FeatureFilms',
  components: {
    FilmThumbnail
  },
  data() {
    return {
      films: [
  { id: 101, title: '短編シリーズ\n「cinemole」全6話', image: require('@/assets/shortfilm_01.jpg') },
  { id: 102, title: '「カミュなんて知らない」\n上映前コメント映像', image: require('@/assets/shortfilm_02.jpg') },
  { id: 103, title: '「追悼特別上映会 ダンサー\n黒沢美香の世界」予告編', image: require('@/assets/shortfilm_03.jpg') },
  { id: 104, title: '「黒沢美香振付作品集\n「ダンス☆ショー」より」予告編', image: require('@/assets/shortfilm_04.jpg') },
  { id: 105, title: '「あしたのあたし」\n天才算数塾Ver.', image: require('@/assets/shortfilm_05.jpg') },
  { id: 106, title: 'YukoNexus6のお引越し記念Live!', image: require('@/assets/shortfilm_06.jpg') },
  { id: 107, title: '敗戦記念日', image: require('@/assets/shortfilm_07.jpg') },
  { id: 108, title: 'ひとつの海', image: require('@/assets/shortfilm_08.jpg') },
  { id: 109, title: '裂あるいは残', image: require('@/assets/shortfilm_09.jpg') },
  { id: 110, title: '渡良瀬', image: require('@/assets/shortfilm_10.jpg') },
]
    };
  },
  computed: {
    reversedFilms() {
      return [...this.films].reverse();
    }
  }
};
</script>

<style scoped>
.feature-films {
  background-color: black;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: white;
  
}
</style>